.player {
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #1b1b1b80;
}

.controls {
  display: flex;
  flex-direction: row;
  width: 20%;
  justify-content: space-between;
  font-size: 3vw;
  color: white;
  margin: 16px 0;
  align-items: center;
}

.small {
  font-size: 1.5vw;
}

@media (max-width: 900px) {
  .controls {
    width: 60%;
    font-size: 2rem;
  }

  .small {
    font-size: 1.5rem;
  }
}
